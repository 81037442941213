<script setup lang="ts">

  import { watch } from 'vue';
  //import { RouterLink } from 'vue-router';
  import { RouterView } from 'vue-router';
  import { useRoute } from 'vue-router';

  const route = useRoute();

  document.addEventListener(
    'fedpo-ready',
    async () => {
      watch(() => route.name, () => {
        const path = route.fullPath;
        setTimeout(function() {
          window.fedpo.setSecondaryNavCurrentItemByUrl(path);
        }, 0);
    });
  });

</script>

<template>

  <!--
    We don't own our own nav anymore... FedPo takes care of this
    <RouterLink to="/">Home</RouterLink> |
    <RouterLink to="/about">About</RouterLink>
   -->

  <RouterView />

</template>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>