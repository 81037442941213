<script setup lang="ts">

</script>

<template>

<div>
  <h1>Application Management (TS)</h1>

  <h3>Things to set up:</h3>

  <ul>
    <li><a href="/apps">My Applications</a></li>
    <li><a href="#">My Test Groups</a></li>
    <li><a href="#">My Tests</a></li>
  </ul>

  <h3>Other things you might want to do:</h3>

  <ul>
    <li><a href="//content.entos.com">Set up content ingestion</a></li>
    <li><a href="//commerce.entos.com">Set up commerce integration</a></li>
  </ul>
</div>

</template>


<style scoped>
  div {
    padding-left: 220px;
    text-align: left;
  }
</style>
