<script setup lang="ts">

</script>

<template>

<div>
  <h1>My Applications</h1>

  <ul>
    <li>
      Backgammon
      <ul>
        <li>Main</li>
      </ul>
    </li>
    <li>
      Chess
      <ul>
        <li>Main</li>
        <li>Beta</li>
      </ul>
    </li>
  </ul>
</div>

</template>


<style scoped>
  div {
    padding-left: 220px;
    text-align: left;
  }
</style>
