import { createRouter, createWebHistory } from 'vue-router';

import HomeView from '@/views/HomeView.vue';
import AppsListView from '@/views/AppsListView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/apps',
    name: 'appsList',
    component: AppsListView
  }
];

const router = createRouter({
  history: createWebHistory(/*@TODO:UNDEFINED import.meta.env.BASE_URL */),
  routes: routes
});

export default router;
