import { createApp } from 'vue';
import router from './router';
import App from './App.vue';

import 'fedpo';

const app = createApp(App);
app.use(router);
app.mount('#app');

// ~~~ FedPo ~~~

// Needs to be global because it is called by the dynamically loaded
// fedpo-main.mjs code, which wouldn't otherwise have access to it
window.goto = function goto(path) {
  router.push({ path: path });
}

document.addEventListener(
  'fedpo-ready',
  async () => {
    const fedpo = window.fedpo;
    await fedpo.init({
      token: undefined,
      siteConfigUrl: process.env.VUE_APP_SITE_CONFIG_URL,
      moduleTextKey: 'APPS',
      primaryNavElementId: 'nav1',
      secondaryNavElementId: 'nav2',
    });

    fedpo.setSecondaryNavData({
      items: [
        {
          textKey: 'home',
          text: {
            'default': 'Home',
            'en': 'Home',
            'es': 'Home ES',
            'fr': 'Home FR'
          },
          url: '/',
          fcn: () => window.goto('/')
        },
        {
          textKey: 'about',
          text: {
            'default': 'About',
            'en': 'About',
            'es': 'About ES',
            'fr': 'About FR'
          },
          url: '/about',
          fcn: () => window.goto('/about')
        },
        {
          textKey: 'apps',
          text: {
            'default': 'My Apps',
            'en': 'My Apps',
            'es': 'My Apps ES',
            'fr': 'My Apps FR'
          },
          url: '/apps',
          fcn: () => window.goto('/apps')
        },
      ]
    });
    //fedpo.setSecondaryNavCurrentItem('home');
  },
  false,
);
